import utility from './index'

let capabilityApisPromise
let capApiSAASPromise

export const loadCapabilityApis = async config => {
  try {
    if (capabilityApisPromise) {
      await capabilityApisPromise

      return
    }
    const capabilityApisJS = document.createElement('script')
    const prefix = (config.prefix !== '') ? `/${config.prefix}` : ''
    capabilityApisJS.src = `${(config.secure ? 'https://' : 'http://') + config.host + (config.port ? `:${config.port}` : '') + prefix}/resources/assets/external/requirejs/require.js`
    document.head.appendChild(capabilityApisJS)
    capabilityApisJS.loaded = new Promise(resolve => {
      capabilityApisJS.onload = () => { resolve() }
    })
    const capabilityApisCSS = document.createElement('link')
    capabilityApisCSS.href = `${(config.secure ? 'https://' : 'http://') + config.host + (config.port ? `:${config.port}` : '') + prefix}/resources/autogenerated/qlik-styles.css`
    capabilityApisCSS.type = 'text/css'
    capabilityApisCSS.rel = 'stylesheet'
    document.head.appendChild(capabilityApisCSS)
    capabilityApisCSS.loaded = new Promise(resolve => {
      capabilityApisCSS.onload = () => { resolve() }
    })

    capabilityApisPromise = Promise.all([capabilityApisJS.loaded, capabilityApisCSS.loaded])
    await capabilityApisPromise
  } catch (error) {
    throw new Error(error)
  }
}

export const loadCapSAAS = async config => {
  try {
    if (capApiSAASPromise) {
      await capApiSAASPromise

      return
    }
    const tenantUrl = config.host

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = `https://${tenantUrl}/resources/autogenerated/qlik-styles.css`
    document.head.appendChild(link)
    link.loaded = new Promise(resolve => {
      link.onload = () => { resolve() }
    })

    const script = document.createElement('script')
    script.src = `https://${tenantUrl}/resources/assets/external/requirejs/require.js`
    script.onload = async () => {
      window.require.config({
        baseUrl: `https://${tenantUrl}/resources`,
        webIntegrationId: config.webIntegrationId,
      })
    }
    document.body.appendChild(script)
    script.loaded = new Promise(resolve => {
      script.onload = () => { resolve() }
    })

    capApiSAASPromise = Promise.all([link.loaded, script.loaded])
    await capApiSAASPromise
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCapabilityAPIs(config) {
      try {
        await loadCapSAAS(config)
        const prefix = (config.prefix !== '') ? `/${config.prefix}/` : '/'
        window.require.config({
          baseUrl: `${(config.secure ? 'https://' : 'http://') + config.host + (config.port ? `:${config.port}` : '') + prefix}resources`,
          paths: {
            qlik: `${(config.secure ? 'https://' : 'http://') + config.host + (config.port ? `:${config.port}` : '') + prefix}resources/js/qlik`,
          },
          webIntegrationId: config.webIntId,
          config: {
            text: {
              useXhr() {
                return true
              },
            },
          },
        })

        return new Promise(resolve => {
          if (utility.globals.qlik) {
            const app = utility.globals.qlik.openApp(config.appId, { ...config, isSecure: config.secure, prefix })
            // apply theme set in QSE
            app.theme.get().then(theme => {
              theme.apply()
            })
            resolve(app); 
          } else {
            window.require(['js/qlik'], q => {
              utility.globals.qlik = q
              utility.globals.resize = () => {
                q.resize()
              }
              const app = q.openApp(config.appId, { ...config, isSecure: config.secure, prefix })
              resolve(app); 
            })
          }
        })
      } catch (error) {
        throw new Error(error)
    }
  }